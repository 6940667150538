<template>
  <div class="content">
        <button v-if="isCart" class="allclearbn" @click="clearAllCart">カートの内容を削除する</button>
   
<plugin_cartFlow flow="3" />
<div class="steps" v-if="loaded">
  <div class="steptitle">ステップ3：お手紙プレビュー</div>
  <div class="stepcontent">



<div v-for="i in unit" :key="i">
<p class="order_unittitle" v-if="unit!=1">{{i}}枚目の内容を選択</p>


<p class="order_unitdetail" v-if="unit>1">
  {{i}}枚目<!--【{{tagged_values[i-1].letter_callname}}】-->【{{tagged_values[i-1].letter_callname}}】
【{{tagged_values[i-1].letter_target}}】
【{{tagged_values[i-1].letter_lang}}】
のお手紙のプレビューを表示します。</p>

<p class="order_unitdetail" v-else>
 お手紙のプレビューを表示します。</p>




<div class="previewbox" >
  <div v-if="!previewImages.length">しばらくお待ちください。プレビューを読み込み中です..</div>
 <div v-else>
   
<ul><!--v-for="img,i in previewImages[i-1]" :key="i"-->
  <li ><img class="letter_previewimg" :src="previewImages[i-1][0]" alt=""></li>
</ul>

 </div>
  </div>

  </div></div></div>


<div class="footbns">
<a class="bn_back" @click.prevent="back">前へもどる</a>
<a class="bn_next" @click.prevent="submit">次へ進む</a>
</div>


<fullloader :loading="fullloading" />

  </div>
</template>


<script>
//import { mapState, mapMutations, mapActions } from "vuex";
export default  {
  data:function(){
    return {
      values:{},
      previewHTML:"",
      previewImages:[],
      tagged_values:[]
    }
  },
  created:function(){
      this.fullloading=true;
    this.$parent.middle=2;
      this.loaded_countmax=1;
      //まず商品定義を読み込む
      //alert(this.$route.params.label)
      this.get_itemoption(this.$route.params.label).then(()=>{
        this.values=this.record.fields.items.subfields;
        var tagged_values=[];
        for(var i=0;i<this.values.length;i++){
          tagged_values.push(this.get_tagged_values(this.get_option_value(i)));
        }
        this.tagged_values=tagged_values;
        this.loaded_countup();
      });


		var record=this.getSession("record");
      //まず商品定義を読み込む
      this.fetch("previewcreate",{label:this.$route.params.label,record:record}).then((data)=>{
        console.log(data);
        //this.previewHTML=data;
        this.previewImages=data.preview;
      });
  },
  computed:{
    label:function(){
      return this.$route.params.label;
    },
    ID:function(){
      return this.$route.params.ID;
    }
  },
  methods:{
		get_option_value(index){
      if(this.values[index].options.value){
      return JSON.parse(this.values[index].options.value);
      }else{
        return [];
      }
    },
    
    submit:function(){
      this.scrollToTop();
        if(this.ID){
          this.$router.push('/item/'+this.label+'/sender/update/'+this.ID);
        }else{
          this.$router.push('/item/'+this.label+'/sender');
        }
    },
    back:function(){
      this.scrollToTop();
          if(this.ID){
          this.$router.push('/item/'+this.label+'/option1/update/'+this.ID);
          }else{
          this.$router.push('/item/'+this.label+'/option1');
          }
    },
  }
  
}
</script>

<style scoped lang="scss">
h1{
  margin-top: 1em;
  color: #383838;
  font-size: 2em;
}
.content{
  color: #000;
}
.previewbox{
  background :rgb(245, 245, 245);
  text-align: left;
  padding: .5em;
  text-align: center;
  img{
    max-width: 100%;
  }
  ul{
    list-style: none;
  }
}
.letter_previewimg{
	border: solid 1px #B0B0B0;
}
</style>